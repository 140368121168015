<template>
  <div :class="'absolute w-full top-0 pt-1  ' + bg">
    <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
      <nav class="flex bg-opacity-0">
        <div class="flex-none">
          <div class="inline-block flex">
            <a
              href="/"
              class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded cursor-pointer mr-2"
            >
              <span
                class="leading-tight whitespace-nowrap text-2xl text-gray-900"
              >
                <img
                  class="h-12 inline"
                  src="https://media.discordapp.net/attachments/1080058777971212370/1121690579642884186/VirtualKOL_Logo_500x192px_01.png?width=1600&height=384"
                />
              </span>
            </a>
          </div>
        </div>
        <div class="flex-grow text-right">
          <div class="">
            <div
              class="inline-block lg:hidden sm:block"
              v-on:click="menuMobile()"
            >
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-blue-500 cursor-pointer mr-2 mt-2"
              >
                <i class="fas fa-align-justify" style="font-size: 25px"></i>
              </div>
            </div>
          </div>

          <div class="inline-block hidden lg:block">
            <div class="inline-block" v-on:click="menuMobile()">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-blue-400 hover:text-pink-400 cursor-pointer mr-2 mt-3"
              >
                <i class="fas fa-align-justify" style="font-size: 30px"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <div class="h-screen" v-if="show">
      <div class="flex justify-center items-center h-full">
        <div>
          <div
            class="grid grid-cols-1 gap-2 lg:grid-cols-1 md:grid-cols-1 -mt-48 text-center"
          >
            <router-link to="/" class="p-5">
              <h3
                class="max-w-lg mb-6 font-sans text-3xl sm:text-6xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-purple-700 via-pink-500 to-pink-400 hover:text-white cursor-pointer"
              >
                Home
              </h3>
            </router-link>
            <router-link to="/influencerrs" class="p-5">
              <h3
                class="max-w-lg mb-6 font-sans text-3xl sm:text-6xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-purple-700 via-pink-500 to-pink-400 hover:text-white cursor-pointer"
              >
                INFLUENCERS
              </h3>
            </router-link>

            <router-link to="/news" class="p-5">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl sm:text-6xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-purple-700 via-pink-500 to-pink-400 hover:text-white cursor-pointer"
              >
                AI NEWS
              </h2>
            </router-link>

            <router-link to="/contact" class="p-5">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl sm:text-6xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-purple-700 via-pink-500 to-pink-400 hover:text-white cursor-pointer"
              >
                CONTACT US
              </h2>
            </router-link>
          </div>

          <div
            class="flex justify-center mt-5 cursor-pointer animate-bounce"
            @click="menuMobile()"
          >
            <span class="h-20 w-20 flex justify-center items-center">
              <span
                class="animate-ping absolute inline-flex h-20 w-20 rounded-full bg-blue-500 hover:bg-red-400 opacity-75"
              ></span>
              <div
                class="relative inline-flex rounded-full h-20 w-20 bg-purple-600 hover:bg-red-500 flex justify-center items-center text-3xl text-gray-200"
              >
                X
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nativeToast from "native-toast";
import Web3 from "web3";
import Web3Token from "web3-token";
import { TESTNET, CHAIN, NETWORK_VERSION } from "../config";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      CHAIN: CHAIN,
      loading: false,
      token: JSON.parse(localStorage.getItem("Token")),
      networkVersion: JSON.parse(localStorage.getItem("networkVersion")),
      checkChain: false,
      show: false,
      bg: "",
    };
  },
  computed: mapGetters(["getToken", "getUser", "isConnected", "onlineUsers"]),
  methods: {
    ...mapActions(["logout", "setToken"]),

    menuMobile() {
      if (this.show) {
        this.setToken("false");
        this.bg = "";
        this.show = false;
      } else {
        this.setToken("true");
        this.show = true;
        this.bg = "bg-purple-200";
      }
    },
  },
  created() {
    this.setToken("false");
    this.show = false;
    this.bg = "";
  },
};
</script>
